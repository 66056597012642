var forms = (function($) {
	var init = function() {
		_fancySelect();
		_inputClickBehaviour();
	};

	var _fancySelect = function() {
		$(document).bind('gform_post_render', function(){
			// if ($('input[type=file]').length) {
			// 	$('input[type=file]').customFile();
			// }

			$('select').fancySelect();
		});

		$('select').fancySelect();
	};

	var _selectLoadBehaviour = function() {
		$('.ginput_container_select').each(function(index, el) {
			$(el).parent().addClass('active');
		});
	};

	var _inputClickBehaviour = function() {
		
		$(".gravity-form input:not([type='radio']):not([type='checkbox']):not([type='submit']):not([type='button']):not([type='image']):not([type='file']),textarea").on('focusin', function(event) {
			$(this).closest('.gfield').addClass('active');
		});
		$(".gravity-form input:not([type='radio']):not([type='checkbox']):not([type='submit']):not([type='button']):not([type='image']):not([type='file']),textarea").on('focusout', function(event) {
			if ($(this).val().length == 0) {
				$(this).closest('.gfield').removeClass('active');
			}
		});
	};

	var _gFormAjaxCompleteBehaviour = function() {
		
		$(".gravity-form input:not([type='radio']):not([type='checkbox']):not([type='submit']):not([type='button']):not([type='image']):not([type='file']),textarea").each(function(index, el) {
			if ($(this).val().length > 0) {
				$(this).closest('.gfield').addClass('active');
			}
		});
	};

	return {
		init: init,
		inputClickBehaviour: _inputClickBehaviour,
		gFormAjaxCompleteBehaviour: _gFormAjaxCompleteBehaviour,
		selectLoadBehaviour: _selectLoadBehaviour
	};
})(jQuery);
