/* --- App --- */
var md = new MobileDetect(window.navigator.userAgent);

var app = {
	isPlaying: false,
	contentReady: false,
	isMobile: md.phone() ? true : false,
	isTablet: md.tablet() ? true : false,
	osVersion: md.version('Webkit'),
	isWebview: browserdata.webview,
	nav: {
		in: true
	},
	sizes: {},
	plugins: {},
	router: {},
	state: {},
	loader: {},
	device: {},
	dom: {},
	utils: {},
	filters: {},
};

/* --- DOM --- */
app.dom = {
	$html: $('html'),
	$body: $('body'),
	$document: $(document),
	$window: $(window),
	$jaxContent: '#main',
	$scroll_wrapper: '.content-wrap',
	$masthead: '.masthead',
};

app.filters = {
	isOpen		: false,
	isMinimized	: false,
	isMaxPage 	: true,
	endpoints: {
		clients: window.location.origin + '/wp-json/wp/v2/brand',
		services: window.location.origin + '/wp-json/wp/v2/services',
		project_type: window.location.origin + '/wp-json/wp/v2/project_type',
		region: window.location.origin + '/wp-json/wp/v2/region',
		projects: window.location.origin + '/wp-json/wp/v2/projects',
	},
	brandFilters: [],
	servicesFilters: [],
	project_typeFilters: [],
	regionFilters: [],
}

app.loader = {
	linkType: null,
	disabled: false,
	homeSliderAutoplay: true,
}

app.sizes = {
	viewporth: $(window).height(),
	realviewporth: window.innerHeight,
	viewportw: window.innerWidth
};

// Staff page modals

$( document ).on( 'click', '[data-open-modal]', function(e) {
    e.preventDefault();
    var modal = $(this).data('open-modal');
    $('[data-modal="' + modal + '"]').fadeIn();
});

$( document ).on( 'click', '[data-close-modal]', function(e) {
    e.preventDefault();
    var modal = $(this).data('close-modal');
    $('[data-modal="' + modal + '"]').fadeOut();
});

$( document ).on('keyup', function(e) {
  if (e.key == "Escape") $('[data-modal]').fadeOut();
});

// navigator.serviceWorker.register("/app/themes/cube3-2016/js/service-worker.js");