var customCarousel = (function($) {

	var carouselSettings = {
		gridTarget: '.image-grid__slider',
		galleryTarget: '.gallery__container .gallery__block',
	};

	var init = function() {

		
		if ($('.image-grid__slider').length > 0) {
			_imgGridCarousel();
		}
		if ($('.gallery__container .gallery__block').length > 0) {
			_galleryCarousel();
		}

	};

	var _imgGridCarousel = function() {

		$(carouselSettings.gridTarget).each(function(index, el) {
			var self = this;
			var localSettings = {
				length: $(this).find('.slide').length,
				currentIndex: $(this).find('.slide.active').index(),
				currentSlide: $(this).find('.slide.active'),

			};

			// Nav
			var nav = $(self).parent().find('.image-grid__nav');
			_imgGridCarouselNav(self, nav);
			
		});
	};

	var _imgGridCarouselNav = function(carousel, nav) {

		var activeState = false;

		$(nav[0]).find('li').on('click', function(event) {
			event.preventDefault();

			if ($(this).hasClass('active') || activeState == true) return false;
			
			activeState = true;

			var target = $(this).data('slide-id');
			$(nav[0]).find('li').removeClass('active');
			$(this).addClass('active');

			
			var slideTarget = $(carousel).find('[data-slide="'+target+'"]');
			var slideCurrent = $(carousel).find('[data-slide].active');
			var Slider = $(carousel).closest('.image-grid');

			var load_imgGridNextSlide = new TimelineMax({
				onComplete: function() {
					setTimeout(function() {
						activeState = false;
					}, 500);
				}
			});

			load_imgGridNextSlide.to([Slider], 0.7, {
				className:"-=inview",
			}).set([slideTarget], {
				className:"+=active",
			}).set([slideCurrent], {
				className:"-=active",
			}).set([Slider], {
				className:"+=inview",
			});

		});

	};

	var _imgGridCarouselNavComplete = function() {

	};

	var _galleryCarousel = function() {

		$(carouselSettings.galleryTarget).each(function(index, el) {
			var self = this;
			var localSettings = {
				length: $(this).find('.gallery__item').length,
				currentIndex: $(this).find('.gallery__item.active').index(),
				currentSlide: $(this).find('.gallery__item.active'),
			};

			// Nav
			var nav = $(self).parent().find('.gallery__nav');
			_galleryCarouselNav(self, nav);
			
		});
	};

	var _galleryCarouselNav = function(carousel, nav) {

		var activeState = false;

		$(nav[0]).find('li').on('click', function(event) {
			event.preventDefault();

			if ($(this).hasClass('active') || activeState == true) return false;
			
			activeState = true;

			var target = $(this).data('slide-id');
			$(nav[0]).find('li').removeClass('active');
			$(this).addClass('active');

			
			var slideTarget = $(carousel).find('[data-slide="'+target+'"]');
			var slideCurrent = $(carousel).find('[data-slide].active');
			var Slider = $(carousel).closest('.gallery');

			var load_imgGridNextSlide = new TimelineMax({
				onComplete: function() {
					setTimeout(function() {
						activeState = false;
					}, 500);
				}
			});

			load_imgGridNextSlide.to([Slider], 0.3, {
				className:"+=slidetrans",
			}).set([slideTarget], {
				className:"+=active",
			}).set([slideCurrent], {
				className:"-=active",
			}).set([Slider], {
				className:"-=slidetrans",
			});

		});

	};


	return {
		init: init
	};
})(jQuery);
