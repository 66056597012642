var triggerWaypoints = (function($) {

	var init = function() {
		_imageGridScrollBehaviour();
		_articleScrollBehaviour();
		_galleryScrollBehaviour();
		// _navReactiveScrollBehaviour();
		_navReactiveLoadBehaviour();
		_headingScrollBehaviour();

		if (app.sizes.viewportw >= 1024 ) {
			_toolbarScrollBehaviour();
		}
	};

	var _imageGridScrollBehaviour = function() {

		var offset = '75%';
		if ($(window).width() > 1024 ) {
			offset = '75%';
		}

		$('.image-grid').each(function(index, el) {

			// var imgHeight = $(this).find('.featured-cta__image img').height();
			// var imgWidth = $(this).find('.featured-cta__image img').width();

			$(this).addClass('hasAnim');

			var waypoint = new Waypoint({
			  	element: el,
			  	handler: function(direction) {
			    	if (direction === 'down') {

				    	$(this.element).addClass('inview');

					}
			  	},
			  	offset: offset
			})

		});
	};

	var _articleScrollBehaviour = function() {

		var offset = '85%';
		if ($(window).width() > 1024 ) {
			offset = '110%';
		}

		$('.article, .image--portrait').each(function(index, el) {

			// var imgHeight = $(this).find('.featured-cta__image img').height();
			// var imgWidth = $(this).find('.featured-cta__image img').width();

			$(this).addClass('hasAnim');

			// Trying to fix mouseout harsh easing
			// $(this).mouseleave(function(event) {
			// 	var self = this;
			// 	$(self).addClass('mouseout');
			// 	setTimeout(function() {
			// 		$(self).removeClass('mouseout')
			// 	}, 1500);
			// });

			var waypoint = new Waypoint({
			  	element: el,
			  	handler: function(direction) {
			    	if (direction === 'down') {

				    	$(this.element).addClass('inview');

					}
			  	},
			  	offset: offset
			})


		});
	};

	var _galleryScrollBehaviour = function() {

		var offset = '75%';
		if ($(window).width() > 1024 ) {
			offset = '120%';
		}

		$('.gallery, .signup, .page-end-breaker').each(function(index, el) {

			// var imgHeight = $(this).find('.featured-cta__image img').height();
			// var imgWidth = $(this).find('.featured-cta__image img').width();

			$(this).addClass('hasAnim');

			var waypoint = new Waypoint({
			  	element: el,
			  	handler: function(direction) {
			    	if (direction === 'down') {

				    	$(this.element).addClass('inview');

					}
			  	},
			  	offset: offset
			})


		});
	};

	var _headingScrollBehaviour = function() {

		var offset = '95%';
		if ($(window).width() > 1024 ) {
			offset = '100%';
		}

		$('.heading, .paragraph').each(function(index, el) {

			$(this).addClass('hasAnim');

			var waypoint = new Waypoint({
			  	element: el,
			  	handler: function(direction) {
			    	if (direction === 'down') {

				    	$(this.element).addClass('inview');

					}
			  	},
			  	offset: offset
			})


		});
	};

	var _toolbarScrollBehaviour = function() {

		var offset = '95%';
		if ($(window).width() > 1024 ) {
			offset = 0;
		}

		$('.articles__projects').each(function(index, el) {

			var waypoint = new Waypoint({
			  	element: el,
			  	handler: function(direction) {
			    	if (direction === 'down') {

				    	$('body').addClass('toolbar-attach');
				    	$('.project-filters').addClass('min').addClass('xmin').removeClass('max');

					} else if (direction === 'up') {

				    	$('body').removeClass('toolbar-attach');
				    	$('.project-filters').removeClass('xmin').removeClass('min');

					}
			  	},
			  	offset: offset
			})


		});
	};

	var _navReactiveLoadBehaviour = function() {

		var getFirstSectionColor = $('.content-wrap > *').first();

		if (getFirstSectionColor.hasClass('nav-black')) {
			$('body').removeClass('nav--white');
			$('body').addClass('nav--black');
		} else {
			$('body').removeClass('nav--black');
			$('body').addClass('nav--white');
		}
	};

	var _navReactiveScrollBehaviour = function() {

		var offset = 50;

		$('.nav-black').each(function(index, el) {

			var waypointDown = new Waypoint({
			  	element: el,
			  	handler: function(direction) {
			    	if (direction === 'down') {
			    		// console.log('black down');
			    		$('body').removeClass('nav--white');
				    	$('body').addClass('nav--black');

					}
			  	},
			  	offset: offset
			});
			var waypointUp = new Waypoint({
			  	element: el,
			  	handler: function(direction) {
			    	if (direction === 'up') {
			    		// console.log('black up');
			    		$('body').removeClass('nav--white');
				    	$('body').addClass('nav--black');

					}
			  	},
			  	offset: function() {
			    	return -this.element.clientHeight + 50
			  	}	
			});
		});

		$('.nav-white').each(function(index, el) {

			var waypointDown = new Waypoint({
			  	element: el,
			  	handler: function(direction) {
			    	if (direction === 'down') {
			    		// console.log('white down');
			    		$('body').removeClass('nav--black');
				    	$('body').addClass('nav--white');

					}
			  	},
			  	offset: offset
			});
			var waypointUp = new Waypoint({
			  	element: el,
			  	handler: function(direction) {
			    	if (direction === 'up') {
			    		// console.log('white up');
			    		$('body').removeClass('nav--black');
				    	$('body').addClass('nav--white');

					}
			  	},
			  	offset: function() {
			    	return -this.element.clientHeight + 50
			  	}
			});
		});


	};


	return {
		init: init
	};

})(jQuery);