// ==== START DOCUMENT READY TASKS ==== //

// Set page view to top of page on pageload
// $(window).on('unload', function() {
// 	$(window).scrollTop(0);
// });

var modules_loaded = false;



if (app.isMobile) {
	$('html').addClass('mobile');
}
if (app.isTablet) {
	$('html').addClass('tablet');
}

$(document).ready(function($) {

	$('html').addClass('dom-loaded');

    if ($('.home').length == 0 && $('.page-id-20').length == 0) {
        app.filters.isMaxPage = false;
    }

    if ($('body').hasClass('page-id-20')) {
        $('body').addClass('is-portfolio');
    }

    Site.init();

});

var Site = (function($) {

    // DOM caching
    var win = window;

    return {
        init: function(){

            // Main Triggers: Global Functions
            pageLoader.linkDetection();
            pageLoader.init(win);
            
            toolBar.iPhoneHeightFixToolbar();
            toolBar.projectFilteringSession();   
            toolBar.init();
            utilties.init();
            filtersAjax.init();
            loadmoreBehaviour.init();
            // scrollJack.init();
            customSlider.init();
            customCarousel.init();
            forms.init();
            menu.init();

            triggerWaypoints.init();

            if (app.sizes.viewportw < 1024 ) {
                toolBar.resizeChangeLinks();
            }

            if ($('.module-kit__anchorlink').length > 0) {
                var clipboard = new ClipboardJS('.module-kit__anchorlink');
                var notyf = new Notyf();

                clipboard.on('success', function(e) {
                    notyf.confirm('Copied Link');
                    e.clearSelection();
                });
                clipboard.on('error', function(e) {
                    notyf.alert('Failed to Copy, Sorry :(');
                });
            }

        },
        fireWaypoints: function(){
            triggerWaypoints.init();

        },
        reinit: function(){

            // Main Triggers: Global Functions
            pageLoader.linkDetection();
            pageLoader.init(win);

            toolBar.projectFilteringSession();
            toolBar.init();
            utilties.init();
            filtersAjax.init();
            loadmoreBehaviour.init();
            customSlider.init();
            customCarousel.init();
            forms.init();
            menu.init();

            picturefill();
            triggerWaypoints.init();

            if ($('.module-kit__anchorlink').length > 0) {
                new ClipboardJS('.module-kit__anchorlink');
                var clipboard = new ClipboardJS('.module-kit__anchorlink');
                var notyf = new Notyf();

                clipboard.on('success', function(e) {
                    notyf.confirm('Copied Link');
                    e.clearSelection();
                });
                clipboard.on('error', function(e) {
                    notyf.alert('Failed to Copy, Sorry :(');
                });
            }

        }
    };
    

})(jQuery);



// ==== START PAGE VISUALS ==== //
// Pace Loaded
// Pace.on('done', function(e) {

// });

// ==== START GLOBAL RESIZE EVENTS ==== //
$(window).on('resize', function () {
	app.sizes = {
		viewporth: $(window).height(),
		realviewporth: window.innerHeight,
        viewportw: window.innerWidth
	};

    if (app.sizes.viewportw < 1024 ) {
        toolBar.resizeChangeLinks();
    }
    
    toolBar.iPhoneHeightFixToolbar();
});