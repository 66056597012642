var menu = (function($) {
	var init = function() {
		_menuToggle();
		_behaviourScrollDefault();

		if (app.filters.isMaxPage && app.sizes.viewportw >= 1024) {
			$(window).bind( "scroll", _behaviourScrollToolbarActive );
		} else {
			$(window).unbind( "scroll", _behaviourScrollToolbarActive );
		}

		if (!app.filters.isMaxPage && app.sizes.viewportw >= 1024) {
			// $('body').addClass('offtop');
			$('.project-filters').addClass('min').addClass('xmin');

			$(window).bind( "scroll", _behaviourScrollToolbarMin );
		} else {
			$('.project-filters').removeClass('min').removeClass('xmin');
			$(window).unbind( "scroll", _behaviourScrollToolbarMin );
		}

	};

	var _menuToggle = function() {
		$('.burger').on('click', function(event) {
			event.preventDefault();

			$(this).toggleClass('open');
			$('body').toggleClass('menu-open');

			// Global State
			app.nav.in == true ? app.nav.in = false : app.nav.in = true;
		});
	};

	var _behaviourScrollDefault = function() {

		$(window).scroll(function() {
			toolBar.iPhoneHeightFixToolbar();
			if($(window).scrollTop() > 10) {
				$('body').addClass('offtop');
			} else {
				$('body').removeClass('offtop');
			}

		});

	};

	var _behaviourScrollToolbarActive = function() {


		if($(window).scrollTop() != 0 ) {
			if ($('.is-portfolio').length > 0) {
				if ($('.toolbar-attach').length > 0) {
					$('.project-filters').addClass('min');
				}
			} else {
				$('.project-filters').addClass('min');
			}
		} else {
			// $('.project-filters').removeClass('min');
		}


		if ($('.is-portfolio').length > 0) {
			// If window scrolls more than the space of the viewport.
			if($(window).scrollTop() > app.sizes.viewporth) {
				$('.project-filters').addClass('xmin');
				if (!app.filters.isOpen) {
					$('.project-filters').removeClass('max');
				}
			}
		} else {
			// If window scrolls more than the space below the button and bottom of viewport.
			if($(window).scrollTop() > app.sizes.viewporth/10) {
				$('.project-filters').addClass('xmin');
				if (!app.filters.isOpen) {
					$('.project-filters').removeClass('max');
				}
			}
		}

	};

	var _behaviourScrollToolbarMin = function() {

		if($(window).scrollTop() > 10) {
			$('body').addClass('offtop');
			$('.project-filters').addClass('min').addClass('xmin');
			if (!app.filters.isOpen) {
				$('.project-filters').removeClass('max');
			}
		} 

	};

	return {
		init: init,
		behaviourScrollDefault: _behaviourScrollDefault,
	};
})(jQuery);
