var filtersAjax = (function($) {
	// AJAX script for loading more content.

	var self = this;

	var settings = {
		trigger: '[data-ajax-filter]',
		triggerMobile: '.filters__lists__mobile select',
		appendContainer: '#filterpostsContainer'
	};

	var init = function() {
		_filterTax();
	};

	var _filterTax = function() {
		$(settings.trigger).on('click', function(event) {
			event.preventDefault();
			var self 	= this,
				target 	= $(this).data('ajax-filter'),
				location = window.location.origin + '/' + $(this).closest('.filters').data('filter-type') + '-filter/'; 

			// Dont refilter whats already active.
			if (!$(this).hasClass('selected')) {
				$(this).closest('ul').find('a').removeClass('selected');
				_filterAwardsAJAX(target, location);

				$(this).addClass('selected load');
				setTimeout(function() {
					$(self).removeClass('load');
				}, 1000);
			}
		});
		// For mobile dropdown
		$(settings.triggerMobile).change(function(event) {
			event.preventDefault();

			var self 	= this,
				target 	= $(self).find(":selected").data('ajax-filter'),
				locationTarget = window.location.origin + '/' + $(this).closest('.filters').data('filter-type') + '-filter/'; 

			if (target === 'all') {
				location.reload();
			}

			$(this).closest('ul').find('a').removeClass('selected');
			_filterAwardsAJAX(target, locationTarget);

			$(this).addClass('selected load');
			setTimeout(function() {
				$(self).removeClass('load');
			}, 1000);
		});

	};	

	var _filterAwardsAJAX = function(target, location) {

		// TweenLite.to(window, .5, {scrollTo:"#filterpostsContainer"});
		// console.log(location);
		var filterRequest = $.ajax({
			url: location,
			method: "GET",
			data: { tax : target },
			dataType: "html"
		});

		 
		filterRequest.done(function( data ) {

			$('[data-loadmore]').remove();
			
			var outGoingArticles = new TimelineLite({onComplete:_outGoingAwardsArticlesComplete,onCompleteParams:[data]});

			outGoingArticles.staggerTo('#filterpostsContainer > div', 1.25, {
				y: '25%',
				autoAlpha: '0',
				ease:Power2.easeInOut
			}, 0.1);
			
		});
		 
		filterRequest.fail(function( jqXHR, textStatus ) {
			console.log( "There was an issue connecting to the server. " + textStatus );
		});

	};

	// Functions called when ContentIn animation is finished
	var _outGoingAwardsArticlesComplete = function(data) {

		$(settings.appendContainer).empty();
        $(settings.appendContainer).append(data);

        var newArticles = $('#filterpostsContainer .article');

		var incomingArticles = new TimelineLite();

		incomingArticles.set(newArticles, {
			y: '25%',
			autoAlpha: '0',
		}).staggerTo(newArticles, 1.25, {
			y: '0%',
			autoAlpha: '1',
			className: '+=inview',
			ease:Power2.easeInOut
		}, 0.1);
	};


	return {
		init: init
	};

})(jQuery);