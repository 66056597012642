var utilties = (function($) {
	var init = function() {
		_dataSmoothScroll();
	};

	var _dataSmoothScroll = function() {

		$('[data-scrollto]').on('click', function(e) {
			e.preventDefault();

			var target = $(this).attr('href');

			TweenLite.to(window, 1, {
				scrollTo: target,
			});

		});

	};

	return {
		init: init
	};
})(jQuery);
