var toolBar = (function($) {
  console.log('I\'m here')
	var init = function() {
		_projectFiltering();
	};

	function replaceUrlParam(url, paramName, paramValue) {
	    if (paramValue == null) {
	        paramValue = '';
	    }
	    var pattern = new RegExp('\\b('+paramName+'=).*?(&|$)');
	    if (url.search(pattern)>=0) {
	        return url.replace(pattern,'$1' + paramValue + '$2');
	    }
	    url = url.replace(/\?$/,'');
	    return url + (url.indexOf('?')>0 ? '&' : '?') + paramName + '=' + paramValue;
	}

	var _resizeChangeLinks = function() {
		$('.project-filters__submit .project-filters__submit__trigger').attr('data-no-ss', true);
	};

	var _iPhoneHeightFixToolbar = function() {
		if ($.browser.iphone) {
			$('.project-filters__lists, .hero--home .hero__slider, .hero--home .hero__slider .hero__slide').css('height', window.innerHeight);
		} else {
			$('.project-filters__lists, .hero--home .hero__slider, .hero--home .hero__slider .hero__slide').css('height', '');
		}
	};

	var _updateLabels = function(objects, targetFilter) {
		var selectedItemText = '';
		for (var i in objects) {
			var selectedItem = $('[data-p-list-type="'+targetFilter+'"]').find('[data-p-filter-id="'+objects[i]+'"]');
			selectedItemText = selectedItemText.concat(selectedItem.text());
		}
		if (selectedItemText == 0) {
			selectedItemText = 'View all';
		}
		$('[data-for="'+targetFilter+'"] .project-filters__option__selected').html(selectedItemText);
	};

	var _projectFiltering = function() {

		// Minimises toolbar on load if not a focus Page.
		if ($('.home').length === 0 && $('.page-id-20').length === 0 ) {
			$('.project-filters').addClass('min').addClass('xmin');
		} else if ($('.home').length > 0) {
			// Minimises toolbar on hero click
			$('.hero--home').on('click', function() {
				app.loader.homeSliderAutoplay = false;
				$('.project-filters').addClass('min').addClass('xmin').removeClass('max');
			});
		} else if ($('.page-id-20').length > 0) {
			$('.project-filters').removeClass('min');
		}

		var localSettings = {
			toolBar 		: '.project-filters',
			filterOpener	: '[data-p-list-target]',
			filterList		: '[data-p-list]',
			filterSearch	: '.project-filters__submit__trigger',
			filterSearchBar	: '.project-filters__submit .project-filters__submit__trigger',
			filterClear		: '[data-p-util-clear]',
			filterItem		: '[data-p-filter-slug]',
			filterClose		: '.body-wrap__overlay',
			filterOrdering	: '[data-p-ordering]'
		};

		//
		$.expr.filters.offscreen = function(el) {
			var rect = el.getBoundingClientRect();
			return (
	        (rect.x + rect.width) < 0
	             || (rect.y + rect.height) < 0
	             || (rect.x > window.innerWidth || rect.y > window.innerHeight)
	        );
		};

		// Reset Filters State on Resize and not mobile view
		$(window).on('resize', function () {
			if (app.sizes.viewportw >= 1024 ) {
				$(localSettings.filterOpener).removeClass('selected');
				$(localSettings.filterList).removeClass('selected');
				$(localSettings.toolBar).removeClass('open');
				$('body').removeClass('filters-open');
				$('[data-p-list]').closest('.project-filters__lists').css({
					height: 0,
				});
				app.filters.isOpen = false;
			}
		});

		// Filters List Tabs
		$(localSettings.filterOpener).on('click', function(event) {
			event.preventDefault();

			app.filters.isOpen = true;
			var target = $(this).data('p-list-target');
			var containHeight = $('[data-p-list="'+target+'"]').innerHeight();

			//Check if toggle
			var isActive = $(this).hasClass('selected') ? true : false;

			// Resets
			if (app.sizes.viewportw >= 1024 ) {
				$(localSettings.filterOpener).removeClass('selected');
				$(localSettings.filterList).removeClass('selected');
			}

			if (isActive) {
				if (app.sizes.viewportw < 1024 ) {
					$('[data-p-list-target="'+target+'"]').removeClass('selected');
					$('[data-p-list="'+target+'"]').removeClass('selected');
				}

				$(localSettings.toolBar).removeClass('open');
				if (app.sizes.viewportw >= 1024 ) {
					$('body').removeClass('filters-open');
					$('[data-p-list]').closest('.project-filters__lists').css({
						height: 0,
					});
				}
				app.filters.isOpen = false;
				return;
			}

			$(this).addClass('selected');

			if (app.sizes.viewportw >= 1024 ) {
				$('[data-p-list="'+target+'"]').closest('.project-filters__lists').css({
					height: (containHeight),
				});
				$('body').addClass('filters-open');
				setTimeout(function() {
					if ($('.project-filters__lists').is(':off-top')) {
						TweenLite.to(window, 0.2, {
							scrollTo:'.project-filters__lists',
							offsetY: 150
						});
					}
				}, 300);

			}

			$('[data-p-list="'+target+'"]').addClass('selected');
			$(localSettings.toolBar).addClass('open');

			// Global States

		});

		// Adding Filters
		$(localSettings.filterItem).on('click', function(event) {
			event.preventDefault();

			// Return if inactive
			if ($(this).hasClass('inactive') || $(localSettings.toolBar).hasClass('checking')) {
				return;
			}

			var targetID 	= $(this).data('p-filter-id');
			var targetSlug 	= $(this).data('p-filter-slug');
			var type 		= $(this).closest('[data-p-list-type]').data('p-list-type');

			function checkFilters(filter) {
			    return filter != targetID;
			}

			// Realtime Filter updates
			$(localSettings.filterSearch).find('[data-state]').removeClass('active');
			$(localSettings.toolBar).addClass('checking');
			$(localSettings.filterSearch).find('[data-state="checking"]').addClass('active');

			//Check if toggle
			var isActive = $(this).hasClass('selected') ? true : false;

			if (isActive) {
				$(this).removeClass('selected');

				if (type === 'services') {
					app.filters.servicesFilters = app.filters.servicesFilters.filter(checkFilters);
					sessionStorage.setItem('servicesFilters', app.filters.servicesFilters);

					_updateLabels(app.filters.servicesFilters, 'services');

				} else if (type === 'project_type') {
					app.filters.project_typeFilters = app.filters.project_typeFilters.filter(checkFilters);
					sessionStorage.setItem('project_typeFilters', app.filters.project_typeFilters);

					_updateLabels(app.filters.project_typeFilters, 'project_type');

				} else if (type === 'region') {
					app.filters.regionFilters = app.filters.regionFilters.filter(checkFilters);
					sessionStorage.setItem('regionFilters', app.filters.regionFilters);

					_updateLabels(app.filters.regionFilters, 'region');
				}
				$(localSettings.filterSearch).attr('href', window.location.origin + '/portfolio/' + '?services=' + app.filters.servicesFilters + '&project_type=' + app.filters.project_typeFilters + '&region=' + app.filters.regionFilters);

				_projectFilteringRealtimeUpdate(localSettings.filterSearch, localSettings.toolBar);
				return;
			}

			$(this).addClass('selected');

			if (type === 'services') {
				app.filters.servicesFilters.push(targetID);
				sessionStorage.setItem('servicesFilters', app.filters.servicesFilters);

				_updateLabels(app.filters.servicesFilters, 'services');

			} else if (type === 'project_type') {
				app.filters.project_typeFilters.push(targetID);
				sessionStorage.setItem('project_typeFilters', app.filters.project_typeFilters);

				_updateLabels(app.filters.project_typeFilters, 'project_type');

			} else if (type === 'region') {
				app.filters.regionFilters.push(targetID);
				sessionStorage.setItem('regionFilters', app.filters.regionFilters);

				_updateLabels(app.filters.regionFilters, 'region');
			}

			$(localSettings.filterSearch).attr('href', window.location.origin + '/portfolio/' + '?services=' + app.filters.servicesFilters + '&project_type=' + app.filters.project_typeFilters + '&region=' + app.filters.regionFilters);


			// Global States
			_projectFilteringRealtimeUpdate(localSettings.filterSearch, localSettings.toolBar);

		});

		// Clear Filters Button
		$(localSettings.filterClear).on('click', function(event) {
			event.preventDefault();

			// Resets
			$(localSettings.filterItem).removeClass('selected');
			app.filters.servicesFilters = [];
			app.filters.project_typeFilters = [];
			app.filters.regionFilters = [];
			sessionStorage.removeItem('servicesFilters');
			sessionStorage.removeItem('project_typeFilters');
			sessionStorage.removeItem('regionFilters');

			$('.project-filters__option__selected').html('View all');

			// Realtime Filter updates
			// $('[data-p-filter-id]').removeClass('inactive');
			$(localSettings.filterSearch).find('[data-state]').removeClass('active');
			$(localSettings.toolBar).removeClass('checking');
			$(localSettings.filterSearch).find('[data-state="search"]').addClass('active');

			$(localSettings.filterSearch).attr('href', window.location.origin + '/portfolio/');

		});

		// Search Filters Button
		$(localSettings.filterSearchBar).on('click', function(event) {
			var selfe = event;
			if (app.sizes.viewportw >= 1024 ) {
				if ($('.project-filters.min').length > 0 ) {
					selfe.preventDefault();
					$('.project-filters').addClass('max');
					setTimeout(function() {
						$('.project-filters').removeClass('min').removeClass('xmin');
					}, 50);
					return;
				}
			} else {
				event.preventDefault();
				$('body').addClass('filters-open');
			}

		});

		// Search Filters Close Button
		$(localSettings.filterClose).on('click', function(event) {
			event.preventDefault();
			if (app.sizes.viewportw >= 1024 ) {
				$(localSettings.filterOpener).removeClass('selected');
				$(localSettings.filterList).removeClass('selected');
				$('[data-p-list]').closest('.project-filters__lists').css({
					height: 0,
				});
			}
			$(localSettings.toolBar).removeClass('open');
			$('body').removeClass('filters-open');
			app.filters.isOpen = false;

		});

		// Search Filters Ordering Button
		$(localSettings.filterOrdering).each(function(index, el) {

			var currentURL = window.location.origin + window.location.pathname;
			var currentSearch = window.location.search;
			var orderby = $(this).data('p-ordering');

			if(/[?&]ordering=/.test(currentSearch)) {
				$(el).attr('href', replaceUrlParam(window.location.href, 'ordering', orderby));
			} else {
				$(el).attr('href', currentURL + (currentSearch ? currentSearch + '&ordering=' + orderby : '?ordering=' + orderby ) );
			}

		});

	};

	var _projectFilteringSessionStates = function(name, storagefilters, appFilter) {

		var filtersSplit = storagefilters.split(',');
		var selectedItemText = '';
		for (var i in filtersSplit) {
			appFilter.push(filtersSplit[i]);
			var selectedItem = $('[data-p-list-type="'+name+'"]').find('[data-p-filter-id="'+filtersSplit[i]+'"]');
			var accordianOfSelected = $('[data-p-filter-id="'+filtersSplit[i]+'"]').closest('.project-filters__list').data('p-list-type');
			var Item = $('[data-p-list-type="'+name+'"]').find('[data-p-filter-id="'+filtersSplit[i]+'"]');
			selectedItemText = selectedItemText.concat(selectedItem.text());
		  	$(selectedItem).addClass('selected');
		  	if (app.sizes.viewportw < 1024 ) {
		  		$('[data-for="'+accordianOfSelected+'"],[data-p-list-type="'+accordianOfSelected+'"]').addClass('selected');
		  	}
		}
		$('[data-for="'+name+'"] .project-filters__option__selected').html(selectedItemText);

	};

	var _projectFilteringSession = function() {
		var servicesFilters = sessionStorage.getItem('servicesFilters');
		var project_typeFilters = sessionStorage.getItem('project_typeFilters');
		var regionFilters = sessionStorage.getItem('regionFilters');

		var localSettings = {
			toolBar 		: '.project-filters',
			filterSearch	: '.project-filters__submit__trigger',
		};

		if (servicesFilters) {

			app.filters.servicesFilters = [];
			_projectFilteringSessionStates('services', servicesFilters, app.filters.servicesFilters);

		}
		if (project_typeFilters) {

			app.filters.project_typeFilters = [];
			_projectFilteringSessionStates('project_type', project_typeFilters, app.filters.project_typeFilters);

		}
		if (regionFilters) {

			app.filters.regionFilters = [];
			_projectFilteringSessionStates('region', regionFilters, app.filters.regionFilters);

		}

		// Global States
		if (servicesFilters || project_typeFilters || regionFilters) {
			_projectFilteringRealtimeUpdate(localSettings.filterSearch, localSettings.toolBar);
		}
		$('.project-filters__submit__trigger').attr('href', window.location.origin + '/portfolio/' + '?services=' + app.filters.servicesFilters + '&project_type=' + app.filters.project_typeFilters + '&region=' + app.filters.regionFilters);

	};

	var _projectFilteringRealtimeUpdate = function(filterSearchButton, filterToolbar) {

		var self 		= this,
			location 	= window.location.origin + '/project-realtime-filtering/' + '?services=' + app.filters.servicesFilters + '&project_type=' + app.filters.project_typeFilters + '&region=' + app.filters.regionFilters;

		setTimeout( function() {
			var filterRequest = $.ajax({
				url: location,
				method: "GET",
				dataType: "json",
			});

			filterRequest.done(function( data ) {

				// Update Filter options
				var $compiledTermsList = [];
				$.each(data, function(index, val) {
					// console.log(val.terms);
					$.merge($compiledTermsList,val.terms)
				});
				$.unique($compiledTermsList)
				// $('[data-p-filter-id]').addClass('inactive');
				// $.each($compiledTermsList, function(index, val) {
				// 	 $('[data-p-filter-id="'+val+'"]').removeClass('inactive');
				// });


				// Update Messaging
				$(filterToolbar).removeClass('checking');
				$(filterSearchButton).find('[data-state]').removeClass('active');

				if (data.length === 1) {

					// TODO: Force Search Projects button to go straight to page.
					// console.log(data[0].slug);

					// Realtime Filter updates
					$(filterToolbar).addClass('realtime-filters');
					$(filterSearchButton).find('[data-state="one-found"]').addClass('active');

				} else if (data.length > 1) {

					var totalFound = data.length;

					// Realtime Filter updates
					$(filterToolbar).addClass('realtime-filters');
					$(filterSearchButton).find('[data-state="more-found"]').addClass('active');

					$('[data-state="more-found"] > span').html(totalFound);

				} else {

					//Fallback method, If triggers then theres a bug.

					// Realtime Filter updates
					$(filterSearchButton).find('[data-state="error"]').addClass('active');

				}

			});

			filterRequest.fail(function( jqXHR, textStatus ) {
				console.log( "There was an issue connecting to the server. " + textStatus );
			});
		}, 500 );
	};

	return {
		init: init,
		projectFilteringSession:_projectFilteringSession,
		resizeChangeLinks:_resizeChangeLinks,
		iPhoneHeightFixToolbar:_iPhoneHeightFixToolbar,
		projectFilteringRealtimeUpdate:_projectFilteringRealtimeUpdate
	};
})(jQuery);
