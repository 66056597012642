var customSlider = (function($) {

	var sliderSettings = {};

	var init = function() {
		clearInterval(app.loader.homeSliderInterval);
		
		if ($('.hero--home .hero__slider').length > 0) {
			_heroHomeSlider();
		}

	};

	var _heroHomeSlider = function() {

		sliderSettings = {
			length: $('.hero__slide').length,
			currentIndex: $('.hero__slide.active').index(),
			currentSlide: $('.hero__slide.active'),
		};

		$('.hero--home .hero__slide.active .hero__slide__content.hasAnim').addClass('ready');

		$('.hero__slider__arrow').on('click', function(event) {
			event.preventDefault();
			var self = this;

			if (app.loader.disabled) return false;

			// Set Global Restriction
			app.loader.disabled = true;


			if ($(this).hasClass('fill')) return false;

			$(this).toggleClass('fill');

			$('.hero--home .hero__slide .hero__slide__content.hasAnim').removeClass('ready');

			

			setTimeout(function() {
				$(self).removeClass('fill');
			}, 2000);

			if ($(self).hasClass('hero__slider__arrow--prev')) {

				var nextIndex = sliderSettings.currentIndex - 1;

				if (nextIndex < 0) {
					nextIndex = sliderSettings.length - 1;
				}
				
				_load_nextNav(nextIndex);
				_load_nextSlide(nextIndex);

			} else if ($(self).hasClass('hero__slider__arrow--next')) {

				var nextIndex = sliderSettings.currentIndex + 1;

				if (nextIndex >= sliderSettings.length) {
					nextIndex = 0;
				}
				_load_nextNav(nextIndex);
				_load_nextSlide(nextIndex);

			}

		});

		app.loader.homeSliderInterval = setInterval(function(){ Autoplayer() }, 8000);

		function Autoplayer() {
			// console.log('trig');
		    if ($('.hero__slider__arrow').hasClass('fill') || !app.loader.homeSliderAutoplay) return false;

			$('.hero__slider__arrow--next').addClass('fill');

			setTimeout(function() {
				$('.hero__slider__arrow').removeClass('fill');
			}, 2000);

			var nextIndex = sliderSettings.currentIndex + 1;

			if (nextIndex >= sliderSettings.length) {
				nextIndex = 0;
			}

		 	_load_nextNav(nextIndex);
			_load_nextSlide(nextIndex);

		}

	};

	var _load_nextNav = function(nextIndex) {

		var currentNav = $('.hero__slider__nav .hero__slider__counter__switch li.active');
		var nextNav = $('.hero__slider__nav .hero__slider__counter__switch li').eq(nextIndex);

		var load_nextNav = new TimelineMax();

		load_nextNav.to([currentNav], 1, {
			opacity: 0,
			x: '-25%',
			className:"-=active",
			ease: CustomEase.create("custom", "M0,0 C1,0.224 0.446,0.952 1,1")
		}).to([nextNav], 1, {
			opacity: 1,
			x: '-50%',
			className:"+=active",
			ease: CustomEase.create("custom", "M0,0 C1,0.224 0.446,0.952 1,1")
		});

	};

	var _load_nextSlide = function(nextIndex) {

		var nextSlide = $('.hero__slider .hero__slide').eq(nextIndex);
		var nextSlideContent = $('.hero__slider .hero__slide').eq(nextIndex).find('.hero__slide__content.hasAnim');


		var load_nextSlide = new TimelineMax({
			onComplete: _load_nextSlideContent,
			onCompleteParams: [nextIndex, nextSlide]
		});


		load_nextSlide.to([sliderSettings.currentSlide], 1, {
			opacity: 0,
			className:"-=active",
			ease: Power4.easeOut,
		}).to([nextSlide], 1, {
			opacity: 1,
			className:"+=active",
			ease: Power4.easeOut,
		}).set([nextSlideContent],{
			className:"+=ready",
		}, 1);

	};

	var _load_nextSlideContent = function(nextIndex, nextSlide) {


		sliderSettings.currentIndex = nextIndex;
		sliderSettings.currentSlide = nextSlide;

		// Reset Global Restriction
		app.loader.disabled = false;


	};

	return {
		init: init
	};
})(jQuery);
