var scrollJack = (function($) {
	var init = function() {
		// _smoothThatScroll();
	};

	var _smoothThatScroll = function() {

		var scroll = new Smooth({
			preload: true,
		    native: true,
		  	section: document.querySelector(app.dom.$jaxContent),
		  	ease: 0.5
		});

		// console.log(scroll);

		// scroll.init();

	};

	return {
		init: init
	};
})(jQuery);
