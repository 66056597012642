var pageLoader = (function($) {

	var win = window,
		doc = document;

	var robinTransImage = null,
		robinTransTop = null,
		robinTransLeft = null,
		robinTransWidth = null,
		robinTransHeight = null;

	// var t0 = null;
	// var t1 = null;

	var init = function(win) {
		_smoothstate(win);
	};

	var linkDetection = function() {
		$('.js-hero-trans').on('click', function(e) {
			e.preventDefault();
			
			// Set Link type
			app.loader.linkType = 'homeCTA';

			TweenLite.to(window, 0.5, {
				scrollTo:'#main',
			});
		});
		$('.js-article-trans').on('click', function(e) {
			e.preventDefault();
			
			// Set Link type
			app.loader.linkType = 'articleCTA';
			robinTransImage 	= $(this).data('hero');
			robinTransTop 		= $(this).find('.image')[0].getBoundingClientRect().top;
			robinTransLeft 		= $(this).find('.image')[0].getBoundingClientRect().left;
			robinTransWidth	 	= $(this).find('.image').innerWidth();
			robinTransHeight 	= $(this).find('.image').innerHeight();

			// Prevents body from scrollling
			$('body').addClass('lock');
			$(this).addClass('nostate');
		});
	};

	var _smoothstate = function(win) {

		// if (app.loader.disabled) return false;

		var admin_edit = doc.getElementById('wp-admin-bar-edit');

		$(app.dom.$jaxContent).smoothState({
	        debug: true,
	        prefetch: true,
	        scroll: false,
	        cacheLength: 2,
	        allowFormCaching: false,
	        blacklist: 'form, .wp-link, [data-no-ss], .project-filters.min .project-filters__submit__trigger',
	        onBefore: function($currentTarget, $container) {
	        	if (app.loader.disabled) return false;
	        },
	        onStart: {
	            duration: 600,
	            render: function ($container) {
	                $('body').addClass('is-exiting');
	              	// t0 = performance.now();
	            }
	        },
	        onReady: {
	            render: function ($container, $newContent) {
	                var loc = win.location;

	                // if (loc.href.indexOf('string') !== -1) {
	                //     loc.reload();
	                //     return false;
	                // }

	                // if (typeof ga == 'function') {
	                //     ga('send', 'pageview', location.pathname);
	                // }


	                // LOG AJAX DONE
	                $('body').addClass('is-done');
	                $('body').addClass('is-exiting');
	                // t1 = performance.now();
	               	// console.log("Call to doSomething took " + (t1 - t0) + " milliseconds.")

	               	// Start Transitions when all content ready.
	                if (app.loader.linkType == 'homeCTA') {

	                	$('body').addClass('lock');

	                	var heroSetheight = '100vh'

	                	if ($.browser.iphone) {
							heroSetheight = window.innerHeight
						}

	                	// Prep Incoming Content State
	                	var newHeroBorders = [$($newContent[4]).find('.hero__borders .one'), $($newContent[4]).find('.hero__borders .two'), $($newContent[4]).find('.hero__borders .three'), $($newContent[4]).find('.hero__borders .four')];
	                	var newHeroBordersVert = [$($newContent[4]).find('.hero__borders .one'), $($newContent[4]).find('.hero__borders .two')];
	                	var currentImage = [$($newContent[4]).find('.hero__slide.active .hero__slide__bg')];
						var currentContainers = [$($newContent[4]).find('.hero__slider'), $($newContent[4]).find('.hero__slide')];
						var currentContent = [$($newContent[4]).find('.hero__slide.active .hero__slide__content')];
						var currentFilters = [$('.project-filters')];
						if (app.sizes.viewportw >= 768 ) {
		                	TweenLite.set(newHeroBorders, {
								scaleX: 1,
								scaleY: 1,
							});
		                } else {
		                	TweenLite.set(newHeroBordersVert, {
								scaleX: 1,
								scaleY: 1.5,
							});
		                }
						TweenLite.set(currentImage, {
							scale: 1.05
						});
						TweenLite.set(currentFilters, {
							className:"+=min xmin out",
						});
						TweenLite.set($newContent[2], {
							className:"+=min xmin out",
						});
						TweenLite.set(currentContainers, {
							height: heroSetheight,
						});
						if (app.sizes.viewportw >= 768 ) {
							TweenLite.set(currentContent, {
								x: 40,
							});
						}

 						_homeCTA_pageOut($container, $newContent);

	                } else if (app.loader.linkType == 'articleCTA') {
	                	$('body').append('<div class="overlay overlay--robin-transition"><span></span></div>');
	                	$('.overlay--robin-transition').append('<div class="article article__projects"><div class="image"><div class="image__bg" style="background-image:url('+robinTransImage+');"></div></div></div>');
	                	TweenLite.set($('.overlay--robin-transition .article'), {
							top: robinTransTop,
							left: robinTransLeft,
							width: robinTransWidth,
							height: robinTransHeight,
						});

						TweenLite.set($($newContent[4]).find('.hero__slide__content'), {
							className:"+=hasAnim",
						});

						$('.overlay--robin-transition .image__bg').imagesLoaded( { background: true }, function() {
						  	_robinCTA_pageOut($container, $newContent);
						});
						
						
	                } else {

	                	// Prep Incoming Content State
	                	TweenLite.set($newContent[4], {
							opacity: 0
						});
						TweenLite.set($($newContent[4]).find('.project-filters'), {
							opacity: 0
						});

						_default_pageOut($container, $newContent);

	                }
	               	                
	                var old_id = $container.find('.content-wrap').data('id');

	                // $container.html($newContent);

	                if (admin_edit) {
	                    var link = admin_edit.getElementsByTagName('a')[0],
	                        href = link.href;

	                    var new_id = $container.find('.content-wrap').data('id');

	                    link.href = href.replace(old_id, new_id);

	                    // console.log(href);
	                }
	       
	            }
	        }
	    });


	    modules_loaded = true;

	};

	var _default_pageOut = function($container, $newContent) {

		// console.log($newContent[4]);

		var default_pageOut = new TimelineMax({
			onComplete: _default_pageInContent,
			onCompleteParams: [$container, $newContent]
		});

		if (!$('body').hasClass('page-id-20') && !$('body').hasClass('home')) {

			TweenLite.set($newContent[2], {
				className:"+=min xmin",
			});
		}
		
		// console.log($($container).find('.site-wrap'));
		default_pageOut.to($($container).find('.site-wrap'), 1, {
			opacity: 0,
			ease: Power4.easeOut,
		}).to($('.project-filters'), 0.8, {
			opacity: 0,
			ease: Power4.easeOut,
		}, 0);
	};

	var _default_pageInContent = function($container, newContent) {

		
		$('html, body').animate({ scrollTop: 0 }, 0);
		
		$container.html(newContent);
		Site.fireWaypoints();

		if ($('body').hasClass('page-id-20')) {

			$('body').addClass('is-portfolio');
			
		}

		if ($('.content-wrap').find('#activeFilters').length > 0) {
			TweenLite.to(window, 0, {
				scrollTo:'#activeFilters',
			});
		}

		var default_pageIn = new TimelineMax({
			onComplete: _default_pageInContentComplete
		});

		$('body').removeClass('is-exiting');

		default_pageIn.to(newContent[4], 1, {
			opacity: 1,
			ease: Power4.easeOut,
		}).to($('.project-filters'), 1, {
			opacity: 1,
			ease: Power4.easeOut,
		}, 0).set([newContent[4],$('.project-filters')], {
			clearProps: 'all'
		});
	};

	var _default_pageInContentComplete = function() {
		
 		$('body').removeClass('is-done');
 		app.filters.isMaxPage = $('.home').length == 0 && $('.page-id-20').length == 0 ? false : true;

 		if (browserdata.browser === 'ie') {
 			// Triggers resize event for IE
	 		var resizeEvent = window.document.createEvent('UIEvents'); 
			resizeEvent.initUIEvent('resize', true, false, window, 0);
		} else {
			// Triggers resize event for all other browsers
			var resizeEvent = new Event('resize');
		}
		window.dispatchEvent(resizeEvent);

	    setTimeout(Site.reinit(), 100);

	};



	var _homeCTA_pageOut = function($container, $newContent) {

		var homeCTA_pageOut = new TimelineMax({
			onComplete: _homeCTA_pageInContent,
			onCompleteParams: [$container, $newContent]
		});

		
		var borders = ['.hero__borders .one', '.hero__borders .two', '.hero__borders .three', '.hero__borders .four'];
		var bordersVert = ['.hero__borders .one', '.hero__borders .two'];
		var bordersHori = ['.hero__borders .three', '.hero__borders .four'];
		var currentImage = ['.hero__slide.active .hero__slide__bg'];
		var currentInterface = ['.hero__slider .hero__slider__nav'];
		var currentContent = ['.hero__slide.active .hero__slide__content'];
		var currentContentBtn = ['.hero__slide.active .hero__slide__content .btn'];
		var contentNotHero = $($newContent[4]).find(".content-wrap > *:not('.hero')");
		
		TweenLite.set(contentNotHero, {
			opacity: 0
		});

		if (app.sizes.viewportw >= 768 ) {
			homeCTA_pageOut.to(currentContentBtn, .3, {
				opacity: 0,
			}).to(currentInterface, .3, {
				opacity: 0,
			}, 0).to(currentContent, 2, {
				x: 40,
				ease: CustomEase.create("custom", "M0,0 C1,0.224 0.446,0.952 1,1")
			}, 0).to(borders, 2, {
				scaleX: 1,
				scaleY: 1,
				ease: CustomEase.create("custom", "M0,0 C1,0.224 0.446,0.952 1,1")
			}, 0).to(currentImage, 2, {
				scale: 1.05,
				ease: CustomEase.create("custom", "M0,0 C1,0.224 0.446,0.952 1,1")
			}, '-=2');
		} else {
			homeCTA_pageOut.to(currentContentBtn, .3, {
				opacity: 0,
			}).to(currentInterface, .3, {
				opacity: 0,
			}, 0).to(bordersVert, 2, {
				scaleX: 1,
				scaleY: 1.5,
				ease: CustomEase.create("custom", "M0,0 C1,0.224 0.446,0.952 1,1")
			}, 0).to(currentImage, 2, {
				scale: 1.05,
				ease: CustomEase.create("custom", "M0,0 C1,0.224 0.446,0.952 1,1")
			}, '-=2');
		}
	};

	var _homeCTA_pageInContent = function($container, newContent) {

		
		$container.html(newContent);
		Site.fireWaypoints();

		var homeCTA_pageIn = new TimelineMax({
			onComplete: _homeCTA_pageInContentComplete
		});

		$('body').removeClass('is-exiting');

		var bordersVert = ['.hero__borders .one', '.hero__borders .two'];
		var bordersHori = ['.hero__borders .three', '.hero__borders .four'];
		var currentImage = ['.hero__slide.active .hero__slide__bg'];
		var currentContainers = ['.hero__slider', '.hero__slide'];
		var currentContent = ['.hero__slide.active .hero__slide__content'];
		var contentNotHero = $('.site-wrap').find(".content-wrap > *:not('.hero')");
		var currentHero = ['.hero'];


		homeCTA_pageIn.to(currentContainers, 2, {
			height: '65vh',
			ease: CustomEase.create("custom", "M0,0 C1,0.224 0.446,0.952 1,1")
		}).to(bordersVert, 1.7, {
			scaleY: 0,
			ease: CustomEase.create("custom", "M0,0 C1,0.224 0.446,0.952 1,1")
		}, 0.1).to(bordersHori, 1.7, {
			scaleX: 0,
			ease: CustomEase.create("custom", "M0,0 C1,0.224 0.446,0.952 1,1")
		}, 0.1).to(currentImage, 1, {
			scale: 1,
			ease: CustomEase.create("custom", "M0,0 C1,0.224 0.446,0.952 1,1")
		}, 0).to(currentContent, 1, {
			x: 0,
			ease: CustomEase.create("custom", "M0,0 C1,0.224 0.446,0.952 1,1")
		}, 0.75).to(contentNotHero, 1, {
			opacity: 1,
			ease: CustomEase.create("custom", "M0,0 C1,0.224 0.446,0.952 1,1")
		}).set($('body'), {
			className:"-=lock",
		}).set(contentNotHero, {
			clearProps: 'all'
		}).set($('.project-filters'), {
			className:"-=out",
		}).set(currentHero, {
			className:"+=hint",
		});
	};

	var _homeCTA_pageInContentComplete = function() {
		
		// reset link trigger
	    app.loader.linkType = null;
	    app.filters.isMaxPage = $('.home').length == 0 && $('.page-id-20').length == 0 ? false : true;

 		$('body').removeClass('is-done');
 		
 		if (browserdata.browser === 'ie') {
 			// Triggers resize event for IE
	 		var resizeEvent = window.document.createEvent('UIEvents'); 
			resizeEvent.initUIEvent('resize', true, false, window, 0);
		} else {
			// Triggers resize event for all other browsers
			var resizeEvent = new Event('resize');
		}
		window.dispatchEvent(resizeEvent);

	    setTimeout(Site.reinit(), 100);
	};



	var _robinCTA_pageOut = function($container, $newContent) {


		$('body').addClass('lock');

		var robinCTA_pageOut = new TimelineMax({
			onComplete: _robinCTA_pageInContent,
			onCompleteParams: [$container, $newContent]
		});

		var transImageContainer = ['.overlay--robin-transition .article'];
		var transImage = ['.overlay--robin-transition .article .image'];
		var transBG = ['.overlay--robin-transition > span'];

		if (app.sizes.viewportw >= 768 ) {
			robinCTA_pageOut.to(transImage, 0.4, {
        		opacity: 1,
			}).to(transBG, 0.3, {
				opacity: 1,
			}, 0).to(window, 0, {
				scrollTo:'#main',
			}).to(transImageContainer, 1, {
				left: 0,
				top: 0,
				height: '65vh',
				width: '100%',
				minHeight: 550,
				ease: CustomEase.create("custom", "M0,0 C1,0.224 0.446,0.952 1,1")
			}, 0);
		} else {
			robinCTA_pageOut.to(transImage, 0.4, {
        		opacity: 1,
			}).to(transBG, 0.3, {
				opacity: 1,
			}, 0).to(window, 0, {
				scrollTo:'#main',
			}).to(transImageContainer, 1, {
				left: 0,
				top: 0,
				height: '65vh',
				width: '100%',
				minHeight: 550,
				ease: CustomEase.create("custom", "M0,0 C1,0.224 0.446,0.952 1,1")
			}, 0);
		}
	};

	var _robinCTA_pageInContent = function($container, newContent) {

		
		$container.html(newContent);
		Site.fireWaypoints();

		var robinCTA_pageIn = new TimelineMax({
			onComplete: _robinCTA_pageInContentComplete
		});

		$('body').removeClass('is-exiting');

		var transImage = ['.overlay--robin-transition .article'];
		var transBG = ['.overlay--robin-transition > span'];
		var currentHero = ['.hero'];


		robinCTA_pageIn.to(transBG, 1, {
			opacity: 0,
			ease: CustomEase.create("custom", "M0,0 C1,0.224 0.446,0.952 1,1")
		}).to(transImage, 0.2, {
			opacity: 0,
			ease: CustomEase.create("custom", "M0,0 C1,0.224 0.446,0.952 1,1")
		}).set($('.hero__slide__content'), {
			className:"+=ready",
		}, "-=0.3").set(currentHero, {
			className:"+=hint",
		});

	};

	var _robinCTA_pageInContentComplete = function() {
		
		$('.overlay--robin-transition').remove();
		// reset link trigger
	    app.loader.linkType = null;
	    app.filters.isMaxPage = $('.home').length == 0 && $('.page-id-20').length == 0 ? false : true;

 		$('body').removeClass('is-done').removeClass('lock');
 		
 		if (browserdata.browser === 'ie') {
 			// Triggers resize event for IE
	 		var resizeEvent = window.document.createEvent('UIEvents'); 
			resizeEvent.initUIEvent('resize', true, false, window, 0);
		} else {
			// Triggers resize event for all other browsers
			var resizeEvent = new Event('resize');
		}
		window.dispatchEvent(resizeEvent);
 		
	    setTimeout(Site.reinit(), 100);
	};


	return {
		init: init,
		linkDetection: linkDetection
	};
})(jQuery);
