var loadmoreBehaviour = (function($) {
	// AJAX script for loading more content.

	var self = this;

	var settings = {
		trigger: '[data-loadmore]',
		appendContainer: '#filterpostsContainer'
	};

	var init = function() {
		_loadmoreBehaviour();
	};

	var _loadmoreBehaviour = function() {


		var offset = 0;
        $(settings.trigger).click(function(e) {
            e.preventDefault();

            var ordering = $('[data-p-ordering].active').data('p-ordering'),
            	location = '/' + $(this).data('loadmore-type'); 

            if (location == '/blog') {
            	offset = offset+11;
            } else {
            	offset = offset+10;
            }
            
            $.get(location+"-loadmore/?offset="+offset+"&ordering="+ordering,function(data){
                var elems = $.parseHTML( data );
                var articlesCount = $(elems).filter('div.col-md-6');
                if (articlesCount.length < 10) {
                    $('[data-loadmore]').remove();
                }
                
            },'html')
            .done(function(data) {
            	$(settings.appendContainer).append(data);

            	var newArticles = $('#filterpostsContainer .article.new');

				var incomingArticles = new TimelineLite({
					onComplete: _loadmoreBehaviourInContentComplete
				});

				// incomingArticles.set(newArticles, {
				// 	autoAlpha: '0',
				// }).staggerTo(newArticles, 1.25, {
				// 	// className: '+=inview',
				// 	autoAlpha: '1',
				// 	ease:Power2.easeInOut
				// }, 0.1);
			})
            .fail(function() {
                alert( "There was an issue connecting to the server. Sorry!" );
            });
            
        });

	};

	var _loadmoreBehaviourInContentComplete = function() {
		Site.fireWaypoints();
	};

	return {
		init: init
	};

})(jQuery);